<template>
  <div class="extend-wrap">
    <div class="questionnaire">
      <h5 class="title">培训需求调查表</h5>
      <div class="list">
        <div class="l-title">
          感谢您能抽出几分钟时间来参加本次问卷，现在我们就马上开始吧！
        </div>

        <div class="list-topic">
          <div class="list-name">
            <span class="dot">*</span> 1.您每天几点上班？
          </div>
          <div class="list-topic-content">
            <a-radio-group v-model:value="RadioValue">
              <a-radio :style="radioStyle" :value="1">8点</a-radio>
              <a-radio :style="radioStyle" :value="2">8点30分</a-radio>
              <a-radio :style="radioStyle" :value="3">9点</a-radio>
              <a-radio :style="radioStyle" :value="4">
                其他
                <a-input
                  v-if="RadioValue === 4"
                  style="width: 100px; margin-left: 10px"
                />
              </a-radio>
            </a-radio-group>
          </div>
        </div>

        <div class="list-topic">
          <div class="list-name">
            <span class="dot">*</span>2.您目前想学习哪方面的课程？
          </div>
          <div class="list-topic-content">
            <a-checkbox-group v-model:value="multiSelectValue">
              <a-checkbox value="销售类">销售类</a-checkbox>
              <a-checkbox value="财务会计类">财务会计类</a-checkbox>
              <a-checkbox value="市场营销类">市场营销类</a-checkbox>
              <a-checkbox value="平面设计类">平面设计类</a-checkbox>
              <a-checkbox value="程序开发类">程序开发类</a-checkbox>
              <a-checkbox value="人文旅游类">人文旅游类</a-checkbox>
            </a-checkbox-group>
          </div>
        </div>

        <div class="list-topic">
          <div class="list-name">
            <span class="dot">*</span>3.您觉得以下哪张风景图最好看？
          </div>
          <div class="list-topic-content">
            <a-radio-group v-model:value="RadioValue">
              <div class="topic-list">
                <div class="topic-content-item">
                  <img
                    class="item-img"
                    src="../../assets/image/audio-cover.gif"
                    alt=""
                  />
                  <div class="item-radio">
                    <a-radio :style="radioStyle" :value="1"></a-radio>
                    <div>【查看选项说明】</div>
                  </div>
                </div>

                <div class="topic-content-item">
                  <img
                    class="item-img"
                    src="../../assets/image/audio-cover.gif"
                    alt=""
                  />
                  <div class="item-radio">
                    <a-radio :style="radioStyle" :value="2"></a-radio>
                    <div>【查看选项说明】</div>
                  </div>
                </div>

                <div class="topic-content-item">
                  <img
                    class="item-img"
                    src="../../assets/image/audio-cover.gif"
                    alt=""
                  />
                  <div class="item-radio">
                    <a-radio :style="radioStyle" :value="3"></a-radio>
                    <div>【查看选项说明】</div>
                  </div>
                </div>
              </div>
            </a-radio-group>
          </div>
        </div>

        <div class="list-topic">
          <div class="list-name">
            <span class="dot">*</span>4.您觉得以下那几位讲师授课方式最好？
          </div>
          <div class="list-topic-content">
            <a-checkbox-group v-model:value="multiSelectValue">
              <div class="topic-list">
                <div class="topic-content-item">
                  <img
                    class="item-img"
                    src="../../assets/image/audio-cover.gif"
                    alt=""
                  />
                  <div class="item-radio">
                    <a-checkbox class="mtb5" value="张老师">张老师</a-checkbox>
                    <div>【查看选项说明】</div>
                  </div>
                </div>
              </div>
            </a-checkbox-group>
          </div>
        </div>

        <div class="list-topic">
          <div class="list-name">
            <span class="dot">*</span>5.针对此次培训您有什么反馈？
          </div>
          <div class="list-topic-content">
            <a-textarea
              class="mt25"
              placeholder="多行输入"
              v-model:value="textValue"
              showCount
              :rows="4"
              :maxlength="100"
            />
          </div>
        </div>

        <div class="list-topic">
          <div class="list-name">
            <span class="dot">*</span>6.请您对我们的服务质量进行评价，谢谢！
          </div>
          <div class="list-topic-content">
            <a-rate
              class="mt25"
              v-model:value="scoreValue"
              :allow-clear="false"
              allow-half
              @change="unfocused"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();

    const RadioValue = ref(1), // 单选
      radioStyle = reactive({
        display: "block",
        height: "30px",
        lineHeight: "30px",
      });

    const multiSelectValue = ref([]); // 多选

    const textValue = ref(""); // 文本域

    const scoreValue = ref(1); // 评分
    let scoreFlag = false;
    const unfocused = () => {
      scoreFlag = true;
      console.log(scoreFlag);
    };

    const gitData = () => {
      console.log(route.query.id);
    };
    gitData();

    return {
      router,
      route,
      gitData,
      RadioValue,
      radioStyle,
      multiSelectValue,
      textValue,
      scoreValue,
      unfocused,
      scoreFlag,
    };
  },
};
</script>

<style lang="less" scoped>
.extend-wrap {
  padding: 20px 0px;
  background-color: #eee;
  .questionnaire {
    .mixinWrap();
    background-color: #fff;
    padding: 25px 65px;
    .title {
      font-weight: 700;
      font-size: 20px;
      display: flex;
      justify-content: center;
    }
    .list {
      .l-title {
        font-size: 14px;
      }
      .list-topic {
        margin-top: 25px;
        .list-name {
          .dot {
            color: red;
          }
        }
        .list-topic-content {
          padding-left: 25px;
          .topic-list {
            display: flex;
            margin-top: 25px;
            .topic-content-item {
              width: 178px;
              height: 241px;
              border: 1px solid;
              box-sizing: border-box;
              margin-right: 30px;
              .item-img {
                width: 100%;
                height: 172px;
                border: 0;
              }
              .item-radio {
                display: flex;
                align-items: center;
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}

.mt25 {
  margin-top: 25px;
}

.mtb5 {
  margin: 5px 0px;
}

::v-deep(.ant-checkbox-group) {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

::v-deep(.ant-checkbox-wrapper) {
  margin-left: 0;
}

::v-deep(.anticon svg) {
  width: 50px;
  height: 50px;
}
</style>